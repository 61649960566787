<template>
    <b-row>
        <b-col cols="12" md="12">
            <b-card class="rounded-lg" style="min-height: 300px">
                <Loading v-if="notificationLoading" />

                <template v-if="showNotifications">
                    <div :class="['search-input', {open: search}]">
                        <b-form-input
                            v-if="search"
                            autofocus
                            v-model="searchKeyword"
                            @input="searchNotification"
                            @blur="onblur"
                            autocomplete="off"
                            :placeholder="$t(locale.search)"
                            style="height: 100%; border: none"
                        />

                        <div
                            class="position-absolute search-input-icon"
                            style="top: 15px; right: 16px"
                            @click="search = false"
                        >
                            <feather-icon size="1.5x" icon="XIcon" />
                        </div>
                    </div>
                    <b-card-title
                        class="d-flex align-items-center justify-content-between"
                    >
                        <div
                            class="d-flex align-items-center"
                            style="gap: 1.5rem"
                        >
                            <b-img
                                v-bind="imgAttribute"
                                :src="currentUsers.profile_picture"
                            />

                            <p
                                class="mb-0 pb-0 h4"
                                v-text="$t('HomeCard.Notification')"
                            />
                        </div>
                        <div>
                            <b-button
                                v-ripple.400
                                variant="flat-primary"
                                @click="search = true"
                                class="btn-icon rounded-circle"
                            >
                                <feather-icon icon="SearchIcon" size="21" />
                            </b-button>
                        </div>
                    </b-card-title>
                    <b-card-text>
                        <b-row class="">
                            <b-col cols="12" md="6" class="mb-2">
                                <v-select
                                    @input="changeFilter"
                                    v-model="filterBy"
                                    :clearable="false"
                                    :options="$t(locale.filterOptions)"
                                    class="resource-selector d-inline-block"
                                />
                            </b-col>
                            <template v-if="notifications.length > 0">
                                <b-col cols="12">
                                    <virtual-list
                                        class="scrol-area"
                                        style="height: 360px; overflow-y: auto"
                                        scrollable
                                        :data-key="'uuid'"
                                        :data-sources="notifications"
                                        :estimate-size="15"
                                        :data-component="sliderComponent"
                                        @tobottom="onScrollToBottom"
                                    />

                                    <div
                                        v-if="spinnerVirtualList"
                                        class="d-flex justify-content-center"
                                    >
                                        <b-spinner
                                            v-for="data in 3"
                                            :key="data"
                                            type="grow"
                                            label="Loading..."
                                        />
                                    </div>
                                </b-col>
                            </template>

                            <b-col v-else cols="12" class="d-flex flex-column">
                                <div class="d-flex justify-content-center">
                                    <p
                                        class="mb-0 pb-0 font-weight-bold h3"
                                        v-text="$t(locale.empty)"
                                    />
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </template>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
import Ripple from 'vue-ripple-directive';
import NotificationChild from './NotificationChild.vue';
import VirtualList from 'vue-virtual-scroll-list';
import axiosIns from '@/libs/axios';
import vSelect from 'vue-select';

export default {
    name: 'Notifications',
    components: {
        VirtualList,
        vSelect,
    },
    directives: {
        Ripple,
    },

    data() {
        return {
            config: useAppConfig(),
            notificationLoading: true,
            showNotifications: false,
            filterBy: this.$t('Notification.filterOptions')[0],
            nextLinks: '',
            queryParams: {},
            spinnerVirtualList: false,
            searchKeyword: '',
            notifications: [],
            search: false,
            sliderComponent: NotificationChild,
            imgAttribute: {
                width: '30',
                height: '30',
                rounded: true,
            },
            locale: {
                search: 'EventLogCard.searchPlaceHolder',
                empty: 'BillingPageCard.noMatchRecordFound',
                filterOptions: 'Notification.filterOptions',
            },
        };
    },
    watch: {
        'config.refreshNotification.value': {
            handler() {
                this.init();
            },
        },
        'config.openNotifications.value': {
            handler(value) {
                this.openModal(value);
            },
        },

        getLang(value) {
            if (value) {
                this.filterBy = this.$t('Notification.filterOptions').find(
                    element => element.value == this.filterBy.value
                );
            }
        },
    },
    computed: {
        refreshNotification() {
            return this.$store.getters['appConfig/refreshNotification'];
        },

        currentUsers() {
            return this.$store.getters['appConfig/currentUser'];
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        changeFilter(value) {
            if (value.value !== 'all') {
                this.queryParams.read = value.value;
                this.getNotifications();
            } else {
                delete this.queryParams.read;
                this.getNotifications();
            }
        },

        onScrollToBottom(event) {
            this.spinnerVirtualList = true;
            axiosIns
                .get(this.nextLinks)
                .then(res => {
                    const {results} = res.data;
                    const {next} = res.data.links;

                    if (next !== null) {
                        this.nextLinks = next;

                        this.notifications = this.notifications.concat(results);
                    }
                })
                .finally(() => (this.spinnerVirtualList = false));
        },

        openModal(event) {
            const {storeUuidNotification} = useAppConfig();
            storeUuidNotification.value = event.uuid;
        },
        onblur() {
            delete this.queryParams.search;
            this.getNotifications();
            this.search = false;
        },

        searchNotification(value) {
            if (value.length > 0) {
                this.queryParams.search = value;
                this.getNotifications();
            } else {
                this.search = false;
                delete this.queryParams.search;
                this.getNotifications();
            }
        },

        getNotifications(search) {
            const {refreshNotification} = useAppConfig();
            this.$useJwt
                .getNotificationsList({params: this.queryParams})
                .then(res => {
                    const {results} = res.data;
                    const {next} = res.data.links;
                    this.notifications = results;

                    if (next !== null) this.nextLinks = res.data.links.next;

                    refreshNotification.value = false;
                })
                .finally(() => {
                    this.notificationLoading = false;
                    this.showNotifications = true;
                });
        },
        init() {
            this.getNotifications();
        },
    },

    mounted() {
        this.init();
    },
};
</script>

<style scoped lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables.scss';
.divider {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
}
.scrol-area {
    height: 300px;
    overflow: auto;
    scrollbar-color: $primary transparent;
}

.search-input {
    width: 0;
    display: none;
}

.search-input.open {
    background: white;
    border-radius: 0.5rem;
    display: block;
    position: absolute;
    top: 19px;
    width: 100%;
    height: 54px;
    z-index: 2;
    left: 0;
}

.search-input.open .search-input-icon {
    display: block;
}

.search-input-icon {
    cursor: pointer;
}

.row-notif {
    cursor: pointer;
}

.resource-selector {
    width: 100%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
